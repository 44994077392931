const applyParams = (url, params, getDefault) =>
  url.replace(/{([^}]+)}/g, (_, key) => {
    const value = params?.[key] || getDefault(key);

    if (value === undefined) {
      throw new Error(`Unknown param: ${key}. Known params: ${Object.keys(params).join(', ')}`);
    }

    return value;
  });

export default applyParams;