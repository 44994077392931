import queryToString from './utils/queryToString';
import contentTypeMatches from './utils/contentTypeMatches';
import applyParams from './utils/applyParams';

const knownTypes = ['json', 'blob', 'text', 'arrayBuffer'];

export class RadHttpError extends Error {
  response = undefined;

  constructor(response, message) {
    super(message || response?.error?.message);

    this.response = response;
  }
}

export class RadHttp {
  /**
   * @typedef RadHttpOptions
   * @property {Object.<string, *>} headers= Key/value pairs for headers to send with the request.
   * @property {*} [body] The body to pass along with the request. If it is an object, it will be JSON strinigified.
   *   Use rawBody if you want to avoid stringification. Do not use with rawBody.
   * @property {*} [rawBody] The raw body to be passed along exactly as is. Do not use with body.
   * @property {Object.<string, string|number|boolean>} [query={}] Key/value pairs to make up the querystring.
   * @property {Object.<string, *>} [params={}]
   * @property {'json'|'blob'|'text'|'raw'|'arrayBuffer'} [type='json'] The expected type of the response.
   *   If the expected type does not line up with the expected type, an error is thrown.
   *   If 'raw' is specified, the HttpResponse will have an undefined data and it won't be parsed, so it can be
   *     parsed by the user.
   * @property {Object} [fetchOptions={}] Additional options to be passed to fetch directly.
   * @property {boolean} [debounce=false] If true and another request comes in with the same debounce name, previous
   *   calls will complete with no response and debounced = true
   * @property {string} [debounceName] Defaults to the url of the request, without parameters substituted.
   * @property {function(RadHttpResponse)} [responseHandler] A method that is called before the response is returned.
   * @property {boolean} [force=false] If true, the URL will have a cache-busting string appended.
   */

  /**
   * @typedef RadHttpResponse
   * @template T
   * @property {string} url The actual URL given to fetch.
   * @property {Object} options The actual options given to fetch.
   * @property {Response} response The response from fetch()
   * @property {?T} data The parsed data, parsed based on the type,
   *   using either .json(), .blob() or .text()
   * @property {'json'|'blob'|'text'} type
   *  The type of response. It is based on the Content-Type and matches the following:
   *   - application/json => json
   *   - text/* => text
   *   - anything else => blob
   *   If RadHttpOptions.type was specified, it is always this value.
   * @property {Error=} error The error produced while attempting the request, or returned by the server.
   *   Only possible from the server if type is JSON.
   * @property {boolean} debounced Indicates if the request was aborted. If true, data will always be undefined.
   * @property {boolean} ok Indicates if the request was successful.
   */

  /**
   * @type {function(string):string} Method to get default parameters.
   */
  getDefaultParam = () => '';

  /**
   * @type {Object.<string, AbortController>} A dictionary of debounceNames and their AbortController.
   */
  debounceControllers = {};

  /**
   * @type {Boolean} muted=false If true, it will not console.error Errors
   */
  muted = false;

  /**
   * @type {function(RadHttpResponse)}
   */
  defaultResponseHandler = () => {};

  /**
   * Performs an HTTP request.
   * @param {string} verb
   * @param {string} url The URL, which can contain param keys wrapped in curly brackets.
   *   E.g., /patient/{patientId}
   * @param {RadHttpOptions} options
   * @return {Promise.<RadHttpResponse>}
   *
   * Note: option.type defaults to 'json'. If you want a different type, you'll have to manually specify.
   */
  async request(verb, url, { body = undefined, rawBody = undefined, headers = {},
    query = {}, params = {}, type = 'json', fetchOptions = {},
    debounce = false, debounceName = undefined, responseHandler = undefined,
    force = false } = {}) {
    try {
      if (!verb) {
        throw new Error('Must provide an HTTP verb. Unless you need a custom verb, '
          + 'it is recommended to use the HTTP method functions (get(), put(), post(), delete(), patch()).');
      }

      if (!url) {
        throw new Error('Must provide a URL.');
      }

      if ((body !== undefined) && (rawBody !== undefined)) {
        throw new Error('Cannot use "body" and "rawBody" in the same request.');
      }

      if (type && !knownTypes.includes(type)) {
        throw new Error(`Unknown type: ${type}, expected one of: ${knownTypes.join(', ')}`);
      }

      const actualUrl = this.parseUrl(url, { params, force, query });

      const options = {
        headers: { 'Content-Type': 'application/json', ...headers },
        body: typeof body === 'object' ? JSON.stringify(body) : body,
        method: verb,
        ...fetchOptions
      };

      let abortController;
      if (debounce) {
        debounceName ??= url;

        // Debounce the existing request.
        if (this.debounceControllers[debounceName]) {
          this.debounceControllers[debounceName].abort('debounce');
        }

        abortController = new AbortController();
        options.signal = abortController.signal;

        this.debounceControllers[debounceName] = abortController;
      }

      let response;
      let debounced = false;
      try {
        response = await fetch(actualUrl, options);
      } catch (ex) {
        if (typeof ex === 'string' && ex === 'debounce') {
          debounced = true;
        } else {
          // noinspection ExceptionCaughtLocallyJS
          throw ex; // something else went wrong, so just throw it again
        }
      }

      let contentType;
      let data;
      let error;

      if (!debounced) {
        ([contentType] = response.headers.get('Content-Type')?.split(';') || []);

        if (response.status !== 204) {
          try {
            if (contentType === 'application/json' && (!type || type === 'json')) {
              const result = await response.json();
              type = 'json';

              data = result?.data ?? result;
              error = result?.error;
            } else if (contentTypeMatches(contentType, 'text/*') && (!type || type === 'text')) {
              data = await response.text();
              type = 'text';
            } else if (type === 'arrayBuffer') {
              data = await response.arrayBuffer();
              type = 'arrayBuffer';
            } else if (!type || type === 'blob') {
              data = await response.blob();
              type = 'blob';
            }
          } catch (error) {
            throw new RadHttpError({ response, url: actualUrl, options, error });
          }

          if (!data && data !== '') {
            throw RadHttp.#buildTypeMismatchError(type, contentType, response, url, options);
          }
        }
      }

      if (debounce) {
        delete this.debounceControllers[debounceName];
      }

      const result = {
        url: actualUrl,
        options,
        response,
        type,
        data,
        error,
        debounced,
        ok: response?.ok ?? debounced,
        status: response?.status
      };

      (responseHandler ?? this.defaultResponseHandler)?.(result);

      return result;
    } catch (err) {
      if (!this.muted) {
        console.error(err);
      }

      throw err;
    }
  }

  /**
   * @param {string} url
   * @param {RadHttpOptions} [options={}]
   * @return {Promise.<RadHttpResponse>}
   */
  async get(url, options = {}) {
    return this.request('GET', url, options);
  }

  /**
   * @param {string} url
   * @param {RadHttpOptions} options
   * @return {Promise.<RadHttpResponse>}
   */
  async put(url, options = {}) {
    return this.request('PUT', url, options);
  }

  /**
   * @param {string} url
   * @param {RadHttpOptions} options
   * @return {Promise.<RadHttpResponse>}
   */
  async post(url, options = {}) {
    return this.request('POST', url, options);
  }


  /**
   * @param {string} url
   * @param {RadHttpOptions} options
   * @return {Promise.<RadHttpResponse>}
   */
  async delete(url, options = {}) {
    return this.request('DELETE', url, options);
  }

  /**
   * @param {string} url
   * @param {RadHttpOptions} options
   * @return {Promise.<RadHttpResponse>}
   */
  async patch(url, options = {}) {
    return this.request('PATCH', url, options);
  }

  /**
   * @param {string} url
   * @param {RadHttpOptions} options
   * @returns {string}
   */
  parseUrl(url, { params = {}, force = false, query = {} }) {
    if (force) {
      query[`${Date.now()}${Math.floor(Math.random() * Math.pow(10, 10))}`] = true;
    }

    try {
      return `${applyParams(url, params, this.getDefaultParam)}${queryToString(query)}`;
    } catch (ex) {
      throw new Error(`${ex.message}, URL: ${url}`);
    }
  }

  static #buildTypeMismatchError(type, contentType, response, url, options) {
    return new RadHttpError({
      response,
      url,
      options,
      type
    }, `Response expected type ${type}, but Content-Type was ${contentType} which is not acceptable, for URL: ${url}`);
  }
}

const radHttp = new RadHttp();

export default radHttp;