const connect = storeOrStoreGetter => BaseClass => {
  const getStore = typeof storeOrStoreGetter === 'function'
    ? storeOrStoreGetter
    : () => storeOrStoreGetter;

  class Connected extends BaseClass {
    #lastState;
    #unsubscribe;

    #handleChange = () => {
      const state = getStore().getState();

      this.stateChanged(state, this.#lastState);

      this.#lastState = state;
    };

    connectedCallback() {
      const store = getStore();

      this.#lastState = store.getState();
      this.#unsubscribe = store.subscribe(this.#handleChange);

      this.stateChanged(store.getState(), {});

      super.connectedCallback();
    }

    disconnectedCallback() {
      this.#unsubscribe && this.#unsubscribe();
      this.#unsubscribe = undefined;

      super.disconnectedCallback();
    }

    // eslint-disable-next-line no-unused-vars
    stateChanged(currentState, previousState) { }
  }

  return Connected;
};

export default connect;