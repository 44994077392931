import getDefaultParam from './utils/getDefaultParam';
import { configSelector } from './redux/app/selectors';
import { store } from './redux/store';
import router from './router';
import { setStore } from './utils/redux/store';
import radHttp from './utils/rad-http';
import setRouter from './utils/router/setRouter.js';

setStore(store);
setRouter(router);

router.resolve();

radHttp.getDefaultParam = getDefaultParam;
radHttp.defaultResponseHandler = ({ response }) => {
  if (response?.status !== 401) return;

  window.location.href = `${configSelector().loginUrl}?ReturnUrl=${encodeURIComponent(window.location.href)}`;
};

// Must be this style import or else getDefaultParam won't be set in time.
import('./components/rad-app');